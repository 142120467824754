/* styles.css */

.image-link {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 3px; /* Adjust the vertical padding as needed */
}

.image-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)); /* Corrected gradient overlay syntax */
  opacity: 0;
  transform: scale(1.1); /* Scale effect on hover */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.image-link:hover::before {
  opacity: 1;
  transform: scale(1);
}
